import "./Admin.scss";
// import FilterTable from "./FilteringTable";
import DrinkTable from "./DrinkTable";
import OrderTable from "./OrdersTable";
import CostTable from "./CostTable";
import Transaction from "./Transaction";
import CostTransaction from "./CostTransaction";
import IngredientOrderTable from "./IngredientOrderTable";
import IngredientOrderTransaction from "./IngredientOrderTransaction";
import ReviewTransaction from "./ReviewTransaction";
import Review from "./Reviews";
import GlobalFilter from "./GlobalFilter";
import { useState, useEffect} from "react";
import axios from 'axios'



//THE TABLE ELEMENTS SHOULD BE DIRECTLY INTERACTABLE, IF SHE CHANGES AN ELEMENT IT WILL
//UPDATE THE ENTIRE BACKEND

function Admin() {
  const [page, setPage] = useState(1);  
  const [beforeDate, setBeforeDate] = useState(new Date("2000-01-01"));
  const [afterDate, setAfterDate] = useState(new Date("3000-01-01"));
  const [completed, setCompleted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orderTransactionData, setOrderTransactionData] = useState([]);

  useEffect(() => {
    axios.post("/api/order/transaction/findall", {
      "reqPageNum": page,
      "reqBeforeDate": beforeDate,
      "reqAfterDate": afterDate,
      "reqCompleted":completed
    }, {headers: { "Content-Type": "application/json" }})
    .then((response) => {
        setOrderTransactionData(response.data)
      });
  }, [refresh, completed, beforeDate, afterDate, page]);

  const [drinkRefresh, setDrinkRefresh] = useState(false);
  const [drinkData, setDrinkData] = useState([]);
    useEffect(() => {
      axios.get("/api/drink/findall")
      .then((response) => {
          setDrinkData(response.data)
        });
    }, [drinkRefresh]);        

    const [ingredientRefresh, setIngredientRefresh] = useState(false);
    const [ingredientData, setIngredientData] = useState([]);
    useEffect(() => {
      axios.get("/api/cost/findIngredients")
      .then((response) => {
          setIngredientData(response.data)
        });
    }, [ingredientRefresh]);    
  
    
    const [ingredientTransaction, setIngredientTransaction] = useState([]);
    const [ingreTranRefresh, setIngreTranRefresh] = useState(false);
    useEffect(() => {
      axios.get("/api/cost/transactions/availableIngredients")
      .then((response) => {
        setIngredientTransaction(response.data)
        });
    }, [ingreTranRefresh]);    
    
    

    const [costTransaction, setCostTransaction] = useState([]);
    const [costRefresh, setCostRefresh] = useState(false);
    const [costPage, setCostPage] = useState(1);  
    useEffect(() => {
      axios.post("/api/cost/findAll",{
        "pageNum": costPage,
      }, {headers: { "Content-Type": "application/json"}})
      .then((response) => {
         setCostTransaction(response.data)
        });
    }, [costRefresh, costPage]);    

    const [costOne, setCostOne] = useState([]);
    const [costOneRefresh, setCostOneRefresh] = useState(false);
    useEffect(() => {
      axios.get("/api/cost/findNotIngredients")
      .then((response) => {
         setCostOne(response.data)
        });
    }, [costOneRefresh]); 

    const [reviewPage, setReviewPage] = useState(1);  
    const [reviews, setReviews] = useState([]);
    const [reviewRefresh, setReviewRefresh] = useState(false);
    useEffect(() => {
      axios.post("/api/customer/request/findallreview",{
        "reqPageNum": reviewPage,
      }, {headers: { "Content-Type": "application/json"}})
      .then((response) => {
        setReviews(response.data)
        });
    }, [reviewRefresh, reviewPage]);    

    const [allCosts, setAllCosts] = useState([]);
    const [allCostRefresh, setAllCostRefresh] = useState(false);
    const [completedCost, setCompletedCost] = useState(false);
    const [allCostPage, setAllCostPage] = useState(1);  
    useEffect(() => {
      axios.post("/api/cost/transactions/all",{
        "pageNum": allCostPage,
        "reqFindAll":completedCost,
        "reqBeforeDate": beforeDate,
        "reqAfterDate": afterDate,
      }, {headers: { "Content-Type": "application/json"}})
      .then((response) => {
         setAllCosts(response.data)
        });
    }, [allCostRefresh, allCostPage, completedCost, beforeDate, afterDate]);    

    const [activeSection, setActiveSection] = useState(null);

  const handleNavClick = (section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <>
      <nav className="navbar">
        <ul className="navbar-list">
          <li
            className={`navbar-item ${activeSection === "quickAdd" ? "active" : ""}`}
            onClick={() => handleNavClick("quickAdd")}
          >
            Quick Add
          </li>
          <li
            className={`navbar-item ${activeSection === "viewDrinks" ? "active" : ""}`}
            onClick={() => handleNavClick("viewDrinks")}
          >
            View Drinks
          </li>
          <li
            className={`navbar-item ${activeSection === "viewIngredients" ? "active" : ""}`}
            onClick={() => handleNavClick("viewIngredients")}
          >
            View Ingredients
          </li>
          <li
            className={`navbar-item ${activeSection === "addUseIngredients" ? "active" : ""}`}
            onClick={() => handleNavClick("addUseIngredients")}
          >
            Add/Use Ingredients
          </li>
          <li
            className={`navbar-item ${activeSection === "editDrinks" ? "active" : ""}`}
            onClick={() => handleNavClick("editDrinks")}
          >
            Edit Drinks
          </li>
          <li
            className={`navbar-item ${activeSection === "viewCosts" ? "active" : ""}`}
            onClick={() => handleNavClick("viewCosts")}
          >
            View Costs
          </li>
          <li
            className={`navbar-item ${activeSection === "createCosts" ? "active" : ""}`}
            onClick={() => handleNavClick("createCosts")}
          >
            Create Costs
          </li>
          <li
            className={`navbar-item ${activeSection === "viewReviews" ? "active" : ""}`}
            onClick={() => handleNavClick("viewReviews")}
          >
            View Reviews
          </li>
          <li
            className={`navbar-item ${activeSection === "addDeleteEditReviews" ? "active" : ""}`}
            onClick={() => handleNavClick("addDeleteEditReviews")}
          >
            Add/Delete/Edit Reviews
          </li>
        </ul>


      </nav>

<div id = "adminPage">

      <section id="quickAdd" className="section">

        <div id = "headerTitle">
          <h1> Quick Add </h1>
        </div>
        <br/>

        <div  id = "container">
            <Transaction data = {drinkData} refresh = {drinkRefresh} setRefresh = {setDrinkRefresh}/>
        </div>
        <br></br>
        </section>


        <br/>
        <section id="viewDrinks" className="section">

        <div id = "headerTitle">
          <h1> View Drinks </h1>
        </div>
        <br/>


        <GlobalFilter page = {page} beforeDate = {beforeDate} afterDate = {afterDate} completed = {completed} setCompleted = {setCompleted} setBeforeDate = {setBeforeDate} setAfterDate = {setAfterDate} setPage = {setPage} />
        <div id = "orderContainer">
          <OrderTable refresh = {refresh} setRefresh = {setRefresh} data = {orderTransactionData} completed = {completed} beforeDate = {beforeDate} afterDate = {afterDate} page = {page} />
        </div>
        <br/>
        </section>
        <section id="viewIngredients" className="section">


        <div id = "headerTitle">
          <h1> View Ingredients </h1>
        </div>
          <div> 
            <IngredientOrderTable data = {allCosts} refresh = {allCostRefresh} setRefresh = {setAllCostRefresh} page = {allCostPage} setPage = {setAllCostPage} completed = {completedCost} setCompleted = {setCompletedCost} beforeDate = {beforeDate} afterDate = {afterDate}/>
          </div>
          </section>

          <section id="addUseIngredients" className="section">

        <div id = "headerTitle">
          <h1> Add/Use Ingredients </h1>
        </div>
        <br/>
          <div  id = "container">
           <IngredientOrderTransaction data = {ingredientData} transactionData = {ingredientTransaction} costData = {costOne} refresh = {ingreTranRefresh} setRefresh = {setIngreTranRefresh} setCostRefresh = {setCostOneRefresh}/>
          </div>
        <br/>
        </section>
        <section id="editDrinks" className="section">

        <div id = "headerTitle">
          <h1> Edit Drinks </h1>
        </div>

          <div>
            <DrinkTable data = {drinkData} refresh = {drinkRefresh} setRefresh = {setDrinkRefresh}/>
          </div>
          </section>
          <section id="viewCosts" className="section">


        <div id = "headerTitle">
          <h1> View Costs </h1>
        </div>
        <br/>

          <div> 
            <CostTable data = {costTransaction} refresh = {costRefresh} setRefresh = {setCostRefresh} page = {costPage} setPage = {setCostPage} />
          </div>

          </section>
          <section id="createCosts" className="section">

        <div id = "headerTitle">
          <h1> Create Costs </h1>
        </div>
        <br/>
          <div  id = "container">
           <CostTransaction data = {ingredientData} refresh = {costRefresh} setRefresh = {setCostRefresh}/>
          </div>
        <br/>
        </section>
        <section id="viewReviews" className="section">

        <div id = "headerTitle">
          <h1> View Reviews </h1>
        </div>
          <div> 
            <Review data = {reviews} refresh = {reviewRefresh} setRefresh = {setReviewRefresh} page = {reviewPage} setPage = {setReviewPage}/>
          </div>
          <br/>
          </section>
          <section id="addDeleteEditReviews" className="section">

        <div id = "headerTitle">
          <h1> Add/Delete/Edit Reviews </h1>
        </div>
        <br/>
          <div  id = "container">
           <ReviewTransaction data = {reviews} refresh = {reviewRefresh} setRefresh = {setReviewRefresh} />
          </div>
        </section>

        
        {/* <FilterTable /> */}
      </div>
    </>
  );
}
export default Admin;
