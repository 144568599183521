import logo from "../../assets/scogo.png";
import "./Loader.scss"

const Loader = () => {
    return (
        <div className = "loader__bg">
            <img src = {logo} alt = "Scobaby Loader Logo" className = "loader__image"/>
        </div>
    );
};

export default Loader;