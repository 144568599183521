import "./Admin.scss";
import { useMemo } from "react";
import { useTable } from "react-table";
import { CostList } from "./columns";
import { useState, useRef } from "react";


import axios from 'axios'
import { Toast } from 'primereact/toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function IngredientBaseTable(props) {
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [cancelId, setCancelId] = useState(0);

  const handleClickOpen = (id) => {
    setOpen(true);
    setCancelId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function saveValue(cell, cost){
    console.log(cell)
    axios.put("/api/cost/update",{
      "reqCostId": parseInt(cell.PK_CostId),
      "reqCostName": cost[0].value,
      "reqType": cost[1].value,
      "reqSize": cost[2].value,
      "reqNotes": cost[3].value
  })
    .then(
        props.setRefresh(props.refresh)
    );
  }


  const accept = () => {
    var id = parseInt(cancelId)
    axios.delete("/api/cost/delete", {data:{
      "reqCostId": id
    }, headers: { "Content-Type": "application/json" }})
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deleted!', life: 3000 });
  })
    setOpen(false);
    props.setRefresh(!props.refresh)
    return;
  }
  
  const columns = useMemo(() => CostList, []);
  const data = props.data;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="genTable">
       <Toast ref={toast} />
      <table {...getTableProps()} className="ingredient__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}><div contentEditable='true' onInput={e => cell.value = e.currentTarget.textContent}> {cell.render("Cell")} </div></td>
                  );
                })}
                <td><button onClick= {()=>saveValue(row.original, row.cells)}>save</button></td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br></br>
      <div id = "reviewFilter">
        Page:{" "}
        <button id = "change" onClick = {()=>props.setPage(props.page - 1)}>  &lt; </button>
        <p>{props.page}</p>
        <button id = "change" onClick = {()=>props.setPage(props.page + 1)}> &gt; </button>
      </div>
      
    </div>
  );
}

export default IngredientBaseTable;
