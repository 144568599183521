import "./Admin.scss";
import { useState, useRef } from "react";
import axios from 'axios'
import { Toast } from 'primereact/toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Transaction = (props) => {
    const [addValue, setAddValue] = useState(0)
    const [notes, setNotes] = useState("Good Job!")
    const [drinkName, setDrinkName] = useState("")
    const [drinkId, setDrinkId] = useState(0)
    const toast = useRef(null);
    const [open, setOpen] = useState(false);

    function handleSelect(e){
        console.log(e)
        setDrinkId(e.target.value)
        setDrinkName(e.target[e.target.selectedIndex].text)
    }

    const handleClose = () => {
        setOpen(false);
    };

    function accept(){
        axios.post("/api/order/transaction/generic", {
            "reqDrinkId": drinkId,
            "reqNumDrinks": addValue,
            "reqOrderNotes": notes
          }, {headers: { "Content-Type": "application/json" }})
          .then(data => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Added!', life: 3000 });
            setOpen(false);
            props.setRefresh(true)
          })
    }
    
    return (    
        <div className = "transaction">
            <Toast ref={toast} />
             <div className = "transaction__add">
                <h4>Quick Add</h4>
                <label className = "transaction__name--label">Drink Name</label>
                <select name="drink" onChange={(e)=>handleSelect(e)} value={props.data.DrinkName}>
                    <option>Please Select a Drink</option>
                    {props.data.map((e) => {
                        return <option value={e.PK_DrinkId}>{e.DrinkName}</option>;
                    })}
                </select>
                <label className = "transaction__amount--label">How many?</label>
                <input type = "number" onChange={(e)=>setAddValue(e.target.value)}></input>
                <label className = "transaction__amount--label">Order Notes</label>
                <input type = "text" onChange={(e)=>setNotes(e.target.value)}></input>
            </div>
            <div className = "transaction__preview">
                <p>{'Just sold '+parseInt(addValue)+' '+ drinkName + '! with notes: ' +notes}</p>
                <Button variant="outlined" onClick={() => setOpen(true)}>
                    Confirm
                </Button>
            </div>
            <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
            {"Delete?"}
            </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Confirm Add?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={accept} autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Transaction;