import "./CheckoutItem.scss";
import { useState, useRef } from "react";
import { ConfirmDialog } from 'primereact/confirmdialog'; 
import axios from 'axios'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const CheckoutItem = (props) => {
  function writeOrder() {
    var drinkOrder = []
    var emailDrinkInfo = []
    var currCart = getSessionStorageOrDefault('cart', []);
    for(let i = 0; i < currCart.length; i++){
      for(let j = 0; j < parseInt(currCart[i].quantity);j++)
        drinkOrder.push(currCart[i].id)
      emailDrinkInfo.push({
        "name": currCart[i].name,
        "cost": currCart[i].cost,
        "quantity": currCart[i].quantity
      })
    }
    axios.all([
      axios.post("/api/order/buy/create", {
        "reqCustomerName": props.name,
        "reqOrderNotes" : props.notes,
        "reqInstagramHandle" : props.insta,
        "reqEmailAddress": props.email,
        "reqDrinkOrders": drinkOrder
      }, { headers: { "Content-Type": "application/json" }}),
      axios.post("/api/email", {
        "reqToName" : props.name,
        "reqToEmail" : props.email,
        "reqOrder": emailDrinkInfo
      }, { headers: { "Content-Type": "application/json" }})
    ])
    .then(() => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Order placed!', life: 3000 });
        window.location.href="/confirm";
    })
    .catch(() => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Error. Please ensure you have items in your cart, all required fields are filled in or that the Email Input is correct. Please double check', life: 3000 });
    }) 
    
  } 

  const toast = useRef(null);

  const accept = () => {
    writeOrder(); 
  }  
  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  }

  const [isHovered, setIsHovered] = useState(false);
  const [editIndex, setEditIndex]= useState();
  const [visible, setVisible] = useState(false);
  
  const [cart, setCart] = useState(
    getSessionStorageOrDefault('cart', [])
  );

  let localCart = getSessionStorageOrDefault("cart", []);
  var totalCost = 0;
  localCart.forEach(elem => {
    totalCost += parseFloat((parseFloat(elem.cost).toFixed(2) * parseFloat(elem.quantity)))
  });

  const addItem = (itemID, amount) => {
    let cartCopy = [...cart];
    let existentItem = cartCopy.find(item => item.id == itemID);
    if (!existentItem) 
      return
    existentItem.quantity += amount;
    if (existentItem.quantity <= 0) {
      cartCopy = cartCopy.filter(item => item.id != itemID)
    }
    setCart(cartCopy);
    let cartString = JSON.stringify(cartCopy);
    sessionStorage.setItem('cart', cartString);

    var totalCart = parseInt(sessionStorage.getItem("total")) + amount;
    if (totalCart<0)
      sessionStorage.setItem("total", 0)
    else
      sessionStorage.setItem("total", totalCart)
    props.setTotal(parseInt(sessionStorage.getItem("total")))
  }
  
  return (
    <div className="checkout__overview">
      <h1 className="checkout__title">Overview</h1>
      <div className="checkout__sub-title">
        <p id = "details_title">Details</p>
        <p id = "quantity_title">Quantity</p>
        <p id = "total_title">Total</p>
      </div>
      <div className="checkout__list">
        {localCart.map((drink) => (
          <div className="checkout__item">
            <p className="checkout__item_name" key = {drink.name}>{drink.name}</p>
            <div className="checkout__buttons">
              <button id="minusButton" 
                onClick={() => {
                  setEditIndex(drink.id);
                  if(editIndex === drink.id) addItem(drink.id, -1);
                }}
              >-</button>
              <div id = "quantity">{drink.quantity}</div>
              <button id="plusButton" 
                onClick={() => {
                  setEditIndex(drink.id);
                  if(editIndex === drink.id) addItem(drink.id, 1);
                }}>+
              </button>
          </div>
          <p className="checkout__item__total">$ {parseFloat((parseFloat(drink.cost) * parseFloat(drink.quantity))).toFixed(2)}</p>
          </div>  
        ))}
      </div>
      <div className="checkout__subtotal">
        <h3>Subtotal</h3>
        <p>$ {totalCost.toFixed(2)}</p>
      </div>
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?" 
            header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
      <Toast ref={toast} />
       <Button 
        id = "buyButton"
        className={isHovered ? 'checkout__button--hover' : 'checkout__button'}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={() => setVisible(true)}
        label = "Place Order"
      />
    </div>
  );
};

export default CheckoutItem;
