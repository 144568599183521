import Admin from "../Components/Admin/Admin"
import React, { Component } from 'react'

import "./admin.css"

import { useState, useBetween} from "react";

const AdminPage = () => {
    const [isVerified, setIsVerified] = useState(localStorage.getItem("passwordSet"));
  
    const checkPw = () => {
      // gets the current input value
      const answer = document.getElementById("password").value;
      console.log(process.env.REACT_APP_PASSWORD)
      if (answer == "Superman@1") { 
        setIsVerified(true);
        localStorage.setItem("passwordSet", true)
      } else {
        alert("Sorry, that's not it");
      }
    };
  
    
    return (
        <div>
        {isVerified ? 

        <div>
        <Admin/>
        </div>
        :
        (
            <div >
                <form id="input" onSubmit={checkPw}>
                <input id="password" name="password" />
                <button>open sesame</button>
                </form>
            </div>
         
        )
      }
        </div>
        
    );
};

export default AdminPage;