import Nav from "./Components/Nav/Nav";
import Home from "./pages/Home";
import PersonalPage from "./pages/PersonalPage";
import AdminPage from "./pages/AdminPage";
import Confirm from "./pages/Confirmation";
import CheckoutPage from "./pages/CheckoutPage";
import ErrorPage from "./pages/ErrorPage";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useState, useBetween} from "react";
import "./global.scss"
import axios from 'axios'

import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primereact/resources/themes/mira/theme.css';   // theme


function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  }

function App() {
    require('dotenv').config()    
    axios.defaults.baseURL = 'https://us-central1-scobaby-570a2.cloudfunctions.net/app';
    


    if (sessionStorage.getItem("cart") == null) sessionStorage.setItem("cart", [])
    if (sessionStorage.getItem("total") == null) sessionStorage.setItem("total", 0)
    

    const [total, setTotal] = useState(
        getSessionStorageOrDefault('total', 0)
      );

    return (

    
      <>
      <script src="https://smtpjs.com/v3/smtp.js"></script>
      <BrowserRouter>
      <Nav total = {total} setTotal = {setTotal}/>
      <Routes>
          <Route path ="/" element = {<Home total = {total} setTotal = {setTotal}/>}/>
          <Route path="/admin" element = {<AdminPage/>}/>
          <Route path="/personal" element = {<ErrorPage/>}/>
          <Route path="/stories" element = {<ErrorPage/>}/>
          <Route path="/checkout" element = {<CheckoutPage total = {total} setTotal = {setTotal}/>}/>
          <Route path="/confirm" element = {<Confirm total = {total} setTotal = {setTotal}/>}/>
          <Route path="*" element = {<ErrorPage/>}/>
      </Routes>
      </BrowserRouter>
      </>
              
        
    )
};

export default App
