import "./Admin.scss";
import {useState} from "react";
import axios from 'axios'


const CostTransaction = (props) => {
    const [costType, setCostType] = useState("One Time Cost")
    const [costName, setCostName] = useState("___")
    const [size, setSize] = useState("___")
    const [notes, setNotes] = useState("___")

    function handleSelect(e){
        console.log(e)
    }

    function updateDrink(){
        axios.post("/api/cost/createCost", {
            "reqCostName": costName,
            "reqType": costType,
            "reqSize": size,
            "reqNotes": notes
          }, {headers: { "Content-Type": "application/json" }})
          .then(
            props.setRefresh(!props.refresh)
          )
    }
    
    return (
        <div className = "transaction">
             <div className = "transaction__add">
                <label htmlFor="trans-type">Cost Type</label>
                <select name = "trans-type" onChange = {(e) => setCostType(e.target.value)}>
                    <option value = "One-Time Cost">One Time Cost</option>
                    <option value = "Ingredient">Ingredient</option>       
                </select>
                <label className = "transaction__amount--label">Cost Name</label>
                <input type = "text" onChange={(e)=>setCostName(e.target.value)}></input>
                <label className = "transaction__amount--label">Size</label>
                <input type = "text" onChange={(e)=>setSize(e.target.value)}></input>
                <label className = "transaction__amount--label">Notes</label>
                <input type = "text" onChange={(e)=>setNotes(e.target.value)}></input>
            </div>
            <div className = "transaction__preview">
                <h4>This action will result in:</h4>
                <p> {'Adding '+ costType + ' named: ' + costName +' to database'+ ' that is ' + size +'. Notes: ' + notes}</p>
                <button onClick={()=>updateDrink()}>Confirm</button>
            </div>
           
        </div>
    );
};

export default CostTransaction;