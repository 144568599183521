import Hero from "../Components/Hero/Hero";
import Products from "../Components/Products/Products";
import Aboutme from "../Components/AboutMe/Aboutme";
import TestimonialPreview from "../Components/TestimonialPreview/TestimonialPreview";
import FurtherDetails from "../Components/FurtherDetails/FurtherDetails";
import Footer from "../Components/Footer/Footer";
import Loader from "../Components/Loader/Loader";
import { useEffect, useState } from "react";

function Home(props) {
  //mimic page load, after 3 seconds set to true then load the actual components
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, []);

  return (
    <>
      {!loaded ? (
        <Loader />
      ) : (
        <>
          <Hero />
          <Products total = {props.total} setTotal = {props.setTotal} />
          <Aboutme />
          <TestimonialPreview />
          <FurtherDetails />
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
