import "./ProductDescription.scss";
import { useState, useEffect, useRef} from "react";
import { Toast } from 'primereact/toast';


function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}


const ProductDescription = (prop) => {
  const toast = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  
  const [cart, setCart] = useState(
    getSessionStorageOrDefault('cart', [])
  );

  const { DrinkName, PK_DrinkId, Description, DrinkCost, Color, LightColor, Available, DrinkOrder } = prop.current;
  
  function addItem(item) {
    if (sessionStorage.getItem("total") == null)
      sessionStorage.setItem("total", 0)
    let cartCopy = [...cart];
    let {order} = item;
    let existingItem = cartCopy.find(cartItem => cartItem.order == order);
    sessionStorage.setItem("total", parseInt(sessionStorage.getItem("total")) + 1)
    if (existingItem) {
        console.log(existingItem)
        existingItem.quantity += item.quantity
    } else {
      cartCopy.push(item)
    }
    prop.setTotal(parseInt(sessionStorage.getItem("total")))
    setCart(cartCopy)    
    let stringCart = JSON.stringify(cartCopy);
    sessionStorage.setItem("cart", stringCart);
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Added to cart!', life: 3000});
  }
  
  //add a prop for the icon
  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <div
        className="product__text tab-hidden"
        key={PK_DrinkId}
        style={{ backgroundColor: Color }}
      >
        <h1 className="product__name">{DrinkName}</h1>
        <p className="product__blob__description">{Description}</p>
        <div className="product__bottom">
          <div
            className="product__inventory"
            style={{
              outline: `1px solid ${Color}`,
            }}
          >
            <span className="product__inventory--counter">{Available}</span>
            <p className="product__inventory--text">In Stock</p>
          </div>
          <button
              onMouseOver={() => {
                setIsHovered(true);
              }}
              onMouseOut={() => {
                setIsHovered(false);
              }}
              className={isHovered ? "product__btn--hover" : "product__btn"}
              style={{
                BackgroundColor: isHovered ? LightColor : Color,
                Color: isHovered ? LightColor : Color,
              }}
              
              onClick={() => 
                addItem({
                  id: PK_DrinkId,
                  name: DrinkName,
                  quantity: 1,
                  cost:DrinkCost,
                  order: DrinkOrder 
                })
              }
            >
              Add To Cart
            </button>
        </div>
      </div>
      <div className="product__blob mobile-hidden">
        <div className="product__blob__text" style={{ backgroundColor: Color }}>
          <h1 className="product__name">{DrinkName}</h1>
          <p className="product__blob__description">{Description}</p>
          <div className="product__bottom">
            <div
              className="product__inventory"
              style={{
                outline: `1px solid ${Color}`,
              }}
            >
              <span className="product__inventory--counter">{Available}</span>
              <p className="product__inventory--text">In Stock</p>
            </div>
            <button
              onMouseOver={() => {
                setIsHovered(true);
              }}
              onMouseOut={() => {
                setIsHovered(false);
              }}
              className={isHovered ? "product__btn--hover" : "product__btn"}
              style={{
                BackgroundColor: isHovered ? LightColor : Color,
                color: isHovered ? LightColor : Color,
              }}
              onClick={() => {
                addItem({
                  id: PK_DrinkId,
                  name: DrinkName,
                  quantity: 1,
                  cost:DrinkCost,
                  order:DrinkOrder
                })
              }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDescription;
