import "./nav.scss"
import { useState, useEffect } from "react";
import NavResponsive from "../NavResponsive/NavResponsive";
import scogo from "../../assets/scogo.png"


function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  }

function Nav(props) {    
    const [menuState, setMenu] = useState(false);

    useEffect(()=>(
        getSessionStorageOrDefault('total', 0)
    )[props.total])

    //if true, remove classlist to display the menu
    function handleMenu() {
        const navResponsive = document.querySelector('.nav__responsive')
        menuState ? navResponsive.classList.remove('nav__hidden') : navResponsive.classList.add('nav__hidden');
        setMenu(prevMenu => !prevMenu);
    }


    return (
        <div className="nav" id = "navBar">
            <div className="nav__container">
                <a href="/#homeId" className="nav__title">
                    <img className="nav__icon" src={scogo} alt="scobaby-icon" />
                    <h1 className="nav__title--text">Scobaby</h1>
                </a>
                <div className="nav__large__nav">
                    <div className="nav__large__list">
                        <a href="/#productsId" className="nav__large__item">My Products</a>
                        <a href="/#aboutId" className="nav__large__item">About Me</a>
                        <a href="/#reviewId" className="nav__large__item">Reviews</a>
                    </div>
                </div>
                <a href="/checkout" className="nav__large__item"><span className="nav__cart material-symbols-outlined" aria-label="shopping-cart">shopping_cart</span> 
                <p id = "count" className="nav__count">{props.total}</p>
                
                </a>
                <div className="nav__burger tab-hidden" onClick={handleMenu}><span className="nav__menu material-symbols-outlined burger-menu ">menu</span></div>
            </div>
            <NavResponsive total ={props.total} setTotal = {props.setTotal}/>
        </div>
    )
}

export default Nav