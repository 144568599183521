import React, { StrictMode } from "react";
import "./TestimonialPreview.scss"
import axios from 'axios'
import { useState, useEffect } from "react";


function TestimonialPreview() {
    const [show, toggleShow] = React.useState(true);

    const [message1, setMessage1] = useState({});
    const [message2, setMessage2] = useState({});
    const [message3, setMessage3] = useState({});
    const [message4, setMessage4] = useState({});
    const [message5, setMessage5] = useState({});
    const [message6, setMessage6] = useState({});

    useEffect(() => {
        axios.get("api/customer/request/findshownreview")
        .then((response) => {
            setMessage1(response.data[0]);
            setMessage2(response.data[1]);
            setMessage3(response.data[2]);
            setMessage4(response.data[3]);
            setMessage5(response.data[4]);
            setMessage6(response.data[5]);
        });
    }, []);   
    

    return (
        <>
        <div className ="review" id = "reviewId">
            <div id = "follow">
            Follow and DM us on Instagram to have your review be featured!
            <a id = "follow" href = "https://docs.google.com/forms/d/e/1FAIpQLSe-jb2a6k9VebRmezAQsP2FUQ4KJFtRnjrYUK8MbV0kAOYOaw/viewform?usp=send_form"> Or click here</a>
            </div>
            
            <div className ="review__container">        
                <div className = "review__card">
                    <p className = "review__content">"{message1.RequestInfo}"</p>
                    <p className = "review__name">- {message1.ContactInfo}</p>
                </div>
                <div className = "review__card">
                    <p className = "review__content">"{message2.RequestInfo}"</p>
                    <p className = "review__name">- {message2.ContactInfo}</p>
                </div>
                <div className = "review__card">
                    <p className = "review__content">"{message3.RequestInfo}"</p>
                    <p className = "review__name">- {message3.ContactInfo}</p>
                </div>
            </div>
            {!show && <div className ="review__container">
                <div className = "review__card">
                    <p className = "review__content">"{message4.RequestInfo}"</p>
                    <p className = "review__name">- {message4.ContactInfo}</p>
                </div>
                <div className = "review__card">
                    <p className = "review__content">"{message5.RequestInfo}"</p>
                    <p className = "review__name">- {message5.ContactInfo}</p>
                </div>
                <div className = "review__card">
                    <p className = "review__content">"{message6.RequestInfo}"</p>
                    <p className = "review__name">- {message6.ContactInfo}</p>
                </div> 
            </div>}
            <button
                className = "review__btn"
                onClick={() => toggleShow(!show)}
                >
                {show ? 'More Reviews' : 'Less Reviews'}
            </button>         
        </div>
        </>
    )
}
export default TestimonialPreview