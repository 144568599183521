import "./Admin.scss";
import { useMemo } from "react";
import { useTable } from "react-table";
import { IngredientTransaction } from "./columns";
import mockIngredient from "../../data/mockIngredient.json";

function IngredientOrderTable(props) {
  const columns = useMemo(() => IngredientTransaction, []);
  const data = props.data;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="genTable">
      <table {...getTableProps()} className="ingredient__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br></br>
      <div id = "reviewFilter">
        Page:{" "}
        <button id = "change" onClick = {()=>props.setPage(props.page - 1)}>  &lt; </button>
        <p>{props.page}</p>
        <button id = "change" onClick = {()=>props.setPage(props.page + 1)}> &gt; </button>
      </div>
      <div id = "profits">
        <p>Show Used: </p>
        <input id = "checkBox" type="checkbox" onChange= {(event)=>props.setCompleted(event.target.checked)}></input>
      </div>
    </div>
    
  );
}

export default IngredientOrderTable;
