import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow,} from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import "./ProductCarousel.scss"

import ProductDescription from '../ProductDescription/ProductDescription';

const ProductCarousel = ({ total, setTotal, list, changeDrink, current }) => {

    function handleChange(item) {
        changeDrink(item.activeIndex+1)
    }
    
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination = {{
                el: '.swiper-pagination', clickable: true
            }}
            navigation = {{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true
            }}
          
            onSlideChange={(swiper) => handleChange(swiper)}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            {list.map((drink) => (
                <SwiperSlide ><img src={drink.ImagePath} className="carousel__item" id = {drink.PK_DrinkId} key = {drink.PK_DrinkId}/></SwiperSlide>))}

            <ProductDescription current = {current} total = {total} setTotal = {setTotal}/>
            
            <div className = "slider-controller">
                <div className = "swiper-button-prev slider-arrow">
                    <ion-icon name = "arrow-back-outline"></ion-icon>
                </div>
                <div className = "swiper-button-next slider-arrow">
                    <ion-icon name = "arrow-forward-outline"></ion-icon>
                </div>
                <div className = "swiper-pagination"></div>
            </div>
        </Swiper>

    )
}


export default ProductCarousel;