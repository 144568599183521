import "./Hero.scss"

function Hero() {

    return (
        <div className="hero" id = "homeId">
            <div className="hero__image">
                <div className="hero__blob" id = "blobId">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className = "hero__blob--el">
                        <path fill="#D3C6B5" d="M43.2,-76C56.4,-67.2,67.8,-56.5,76.6,-43.5C85.4,-30.5,91.7,-15.2,91.2,-0.3C90.7,14.7,83.4,29.3,75.3,43.6C67.2,57.9,58.3,71.7,45.7,81C33,90.2,16.5,94.7,0.9,93.1C-14.7,91.5,-29.3,83.8,-43.5,75.5C-57.7,67.2,-71.4,58.1,-79.4,45.4C-87.5,32.7,-89.9,16.4,-89.9,0C-89.9,-16.4,-87.5,-32.7,-79.6,-45.7C-71.7,-58.6,-58.2,-68.1,-44.1,-76.3C-29.9,-84.5,-14.9,-91.4,0,-91.5C15,-91.6,30,-84.8,43.2,-76Z" transform="translate(100 100)" />
                    </svg>
                    <div className="hero__text">
                    <h2 className="hero__text--header">Fresh, Homemade.</h2>
                    <h1 className="hero__text--brand">Scobaby</h1>
                </div>
                </div>
                
                <img src="/imagesPNG/original-CU.png" alt="original kombucha hero" className="hero__image--photo"></img>
            </div>
        </div>
    )
}

export default Hero