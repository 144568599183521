import { NavLink } from "react-router-dom"

import "./NavResponsive.scss"

const NavResponsive = (props) => {

    return (
        <div className="nav__responsive nav__hidden">
            <div className = "nav__list">
                <a href = "/#homeId" className = "nav__item">Home</a>
                <a href="/#productsId" className="nav__large__item">My Products</a>
                <a href="/#aboutId" className="nav__large__item">About Me</a>
                <a href="/#reviewId" className="nav__large__item">Reviews</a>
                <a href="/checkout" className="nav__large__item"> Checkout</a>
            </div>
        </div>
    )
};

export default NavResponsive;