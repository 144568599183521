import "./Admin.scss";
import { useMemo } from "react";
import { useTable, useState } from "react-table";
import { DrinkList } from "./columns";
import axios from 'axios'

function DrinkTable(props) {

  function saveValue(cell, drink){

    axios.put("/api/drink/update",{
      "reqDrinkCost": drink[1].value,
      "reqAvailable": drink[2].value,
      "reqDescription": drink[3].value,
      "reqDrinkName": drink[0].value,
      "reqColor": drink[4].value,
      "reqDrinkOrder": drink[5].value,
      "reqDrinkId": parseInt(cell.PK_DrinkId)
  })
    .then(
        props.setRefresh(props.refresh)
    );
  }


  const columns = useMemo(() => DrinkList, []);
  const data = props.data;
  

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  return (
    <div className="genTable">
      <table {...getTableProps()} className="drink__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}> <div contentEditable='true' onInput={e => cell.value = e.currentTarget.textContent}> {cell.render("Cell")} </div> </td>
                  );
                })}
                <td><button onClick= {()=>saveValue(row.original, row.cells)}>save</button></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DrinkTable;
