import "./Admin.scss";
import { useGlobalFilter, useTable } from "react-table";
import { OrderList } from "./columns";
import { useState, useRef, useMemo, useEffect } from "react";
import axios from 'axios'
import { Toast } from 'primereact/toast';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function OrderTable(props) {
  const columns = useMemo(() => OrderList, []);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [cancelId, setCancelId] = useState(0);
  const [completeId, setCompleteId] = useState(0);

  const handleClickOpen = (id) => {
    setOpen(true);
    setCancelId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeCompleted = (id) => {
    axios.put("/api/order/transaction/complete", {
      "reqOrderTransactionId": id
    }, {headers: { "Content-Type": "application/json" }})
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Yay!', life: 3000 });
      props.setRefresh(!props.refresh);
    })
  };

  const changeDelivered= (id) => {
    axios.put("/api/order/transaction/delivered", {
      "reqOrderTransactionId": id
    }, {headers: { "Content-Type": "application/json" }})
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Yay!', life: 3000 });
      props.setRefresh(!props.refresh);
    })
  };

  const accept = () => {
    var id = parseInt(cancelId)
    axios.delete("/api/order/transaction/delete", {data:{
      "reqOrderTransactionId": id
    }, headers: { "Content-Type": "application/json" }})
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deleted!', life: 3000 });
  })
    setOpen(false);
    props.setRefresh(!props.refresh)
    return;
  }


  var data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
    },
  );

  return (
    <div className = "genTable">
      <Toast ref={toast} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {"Delete?"}
        </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={accept} autoFocus>
              Yes
            </Button>
          </DialogActions>
      </Dialog>
      <table {...getTableProps()} className="order__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th> <a>     </a> </th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
              <th> <a>Completed</a> </th>
              <th> <a>Delivered</a> </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td> 
                  <div>
                  <Button id = "cancelButton" variant="outlined" onClick={() => handleClickOpen(row.original.PK_OrderTransactionId)}>
                    x
                  </Button>
                  </div> 
                </td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <td><input id = "checkBox" checked={row.original.completed} onChange={()=>changeCompleted(row.original.PK_OrderTransactionId)} type="checkbox"></input></td>
                <td><input id = "checkBox" checked={row.original.delivered} onChange={()=>changeDelivered(row.original.PK_OrderTransactionId)} type="checkbox"></input></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OrderTable;
