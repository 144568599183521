import "./AboutMe.scss";
import vivPic from "../../assets/viv_kombucha.jpg";
import bgFruit from "../../assets/fruit-design1.png";

function Aboutme() {
  return (
    <div className="about" id = "aboutId">
      <div className="about__container">
        <div className="about__image">
          <img
            src={vivPic}
            className="about__image--vivian"
            alt="vivian and randy"
          />
        </div>
        <div className="about__text-containers">
          <div className="about__description">
            <h1 className="about__description--header">Hello!</h1>
            <p className="about__description--text">
            "My name is Vivian Lau, and I am a small business owner that has been brewing kombucha for the past 6 years. 
            Scobaby offers homemade kombucha, which is a vegan probiotic that is known to aid digestion, boost energy, 
            and reduce inflammation. Through Scobaby, I want to share this fermented sweet tea beverage to promote healthier 
            and more affordable drink options."
            </p>
            <p className="about__description--signoff">- Vivian</p>
          </div>
          <div className="about__definition">
            <h1 className="about__definition--name">
              SCOBY{" "}
              <span className="about__definition--name--pro">(SKO-by)</span>
            </h1>
            <p className="about__definition--subtitle">acronym</p>
            <p className="about__definition--detail">
              <span className="about__definition--detail--bold">
                Symbiotic Culture{" "}
              </span>{" "}
              that converts sweet tea to a tangy, slightly effervescent drink that is rich in 
              probiotics, antioxidants, and organic acids.
            </p>
          </div>
        </div>
      </div>
      <img src={bgFruit} alt="background-fruit" className="about__background" />
    </div>
  );
}
export default Aboutme;
