import "./Footer.scss"

function Footer() {
    return (
        <>
            <div id="footer">
                <p id = "text">Made By: Vivian Lau</p>
                <p id = "text">Photos: @jenn.qu</p>
                <p id = "text">Website: Randy Lee & Michael Deng</p>
                <p id = "text">Logo: Joann Lau</p>
            </div>
        </>
    )
}

export default Footer