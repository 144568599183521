import ColumnFilter from "./ColumnFilter";

export const DrinkList = [
    {
        Header: 'Drink Name',
        accessor: 'DrinkName'
    },
    {
        Header: 'Cost',
        accessor: 'DrinkCost'
    },
    {
        Header: 'Available',
        accessor: 'Available'
    },
    {
        Header: 'Description',
        accessor: 'Description'
    },
    {
        Header: 'Color',
        accessor: 'Color'
    },
    {
        Header: 'Order',
        accessor: 'DrinkOrder'
    }
]

export const OrderList = [
    {
        Header: 'Date',
        accessor: 'BuyTime'
    },
    {
        Header: 'Buyer Name',
        accessor: "CustomerName"
    },
    {
        Header: 'Drink',
        accessor: "DrinkName"
    },
    {
        Header: 'Email',
        accessor: "EmailAddress"
    },
    {
        Header: 'Instagram Handle',
        accessor: "InstagramHandle"
    },
    {
        Header: 'Order Notes',
        accessor: "OrderNotes"
    }
]

export const Reviews = [
    {
        Header: 'Name',
        accessor: 'ContactInfo'
    },
    {
        Header: 'Review',
        accessor: 'RequestInfo'
    }
]


export const IngredientTransaction = [
    {
        Header: 'Cost',
        accessor: 'CostName'
    },
    {
        Header: 'Type',
        accessor: 'Type'
    },
    {
        Header: 'Size',
        accessor: 'Size'
    },
    {
        Header: 'Buy Cost',
        accessor: 'BuyCost'
    },
    {
        Header: 'Amount',
        accessor: 'Count'
    },
    {
        Header: 'Buy Date',
        accessor: 'BuyDate'
    },
    {
        Header: 'Use Date',
        accessor: 'UseDate'
    }
]

export const CostList = [
    {
        Header: 'Cost Name',
        accessor: 'CostName'
    },
    {
        Header: 'Type',
        accessor: 'Type'
    },
    {
        Header: 'Size',
        accessor: 'Size'
    },
    {
        Header: 'Notes',
        accessor: 'Notes'
    }
]



