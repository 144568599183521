import "./Confirmation.scss"
import CheckoutItem from "../CheckoutItem/CheckoutItem"
import background from "../../assets/fruit-design1.png"
import CheckoutForm from "../CheckoutForm/CheckoutForm"
import { useState, useEffect } from "react";


const Checkout = (props) => {
    props.setTotal(0);
    sessionStorage.clear();

    return (
        <div className="checkout__content">
            <div className="checkout__review">
                <div id = "innerbox" className="checkout__inner-box">
                    <div id="title">
                        <h1 id = "titleContent" className="checkout__title">Thank You</h1>
                    </div>
                    <p id="disclaimer" className = "checkout__disclaimer">
                        Please send payment in the form of an etransfer to scobabybooch@gmail.com. Be sure to check your email 
                        for a confirmation. Delivery to your location is not guarenteed so please email us to confirm a 
                        pickup location, we are located near the CN Tower. Cancellation requests should be made through email 
                        and be made at least 24 hours in advance. We hope you enjoy your order!
                    </p>
                </div>
            </div>
            <img src={background} className="checkout__background"></img>
        </div>
    );
};

export default Checkout;