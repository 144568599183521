import "./Admin.scss";
import {useState} from "react";
import axios from 'axios'

const Transaction = (props) => {
    const [name, setName] = useState("");
    const [review, setReview] = useState("");

    function addReview(){
        axios.post("/api/customer/request/create", {
            "reqContactInfo": name,
            "reqRequestType": "review",
            "reqRequestInfo": review
          }, {headers: { "Content-Type": "application/json" }})
          .then(
            props.setRefresh(!props.refresh)
          )
    }

    return (
        <div className = "transaction">
             <div className = "transaction__add">
                <p>Reviewer Name</p>
                <input type = "text" onChange={(e)=>setName(e.target.value)}></input>
                <br></br>
                <p>Review</p>
                <input type = "text" onChange={(e)=>setReview(e.target.value)}></input>          
            </div>
            <div className = "transaction__preview">
                <h4>This action will result in:</h4>
                <br></br>
                <p>{review}</p>
                <br></br>
                <p>- {name}</p>
                <button onClick={()=>addReview()}>Add Review</button>
            </div>
           
        </div>
    );
};

export default Transaction;