import "./Admin.scss";
import axios from 'axios'

import { useState, useEffect } from "react";
import { useAsyncDebounce } from "react-table";


const GlobalFilter = (props) => {
  const [outCost, setOutCost] = useState(0);
  const [outRevenue, setOutRevenue] = useState(0);
  const [countNotCompleted, setCountNotCompleted] = useState(0);

  useEffect(() => {
    axios.all([
      axios.post("/api/order/transaction/totalCost", {
        "reqBeforeDate": props.beforeDate,
        "reqAfterDate" : props.afterDate
      }, { headers: { "Content-Type": "application/json" }}),
      axios.post("/api/order/transaction/revenue", {
        "reqBeforeDate" : props.beforeDate,
        "reqAfterDate" : props.afterDate
      }, { headers: { "Content-Type": "application/json" }}),
      axios.get("/api/order/transaction/findNotFinishedCount")
    ]).then(
    axios.spread((cost, revenue, countDrinks) => {
      setOutCost(cost.data.sum ? cost.data.sum: 0);
      setOutRevenue(revenue.data.sum? revenue.data.sum: 0);
      setCountNotCompleted(countDrinks.data.count);
    }))
  }, [props.beforeDate, props.afterDate]);  



  return (
    <div id = "filterBack">
      <div id = "search">
        <p>Drinks Not Completed: {countNotCompleted}</p>
      </div>
      
      <div id = "filterBar">
        
        <div id = "filter">
          Page:{" "}
          <button id = "change" onClick = {()=>props.setPage(props.page - 1)}>  &lt; </button>
          <p>{props.page}</p>
          <button id = "change" onClick = {()=>props.setPage(props.page + 1)}> &gt; </button>
        </div>

        <div id = "beforeDate">
          <p>From Date: </p>
          <input type="date" onChange={(event)=>props.setBeforeDate(event.target.value)}/>
        </div>

        <div id = "afterDate">
          <p>To Date: </p>
          <input type="date" onChange={(event)=>props.setAfterDate(event.target.value)}/>
        </div>

        <div id = "profits">
          <p>Show Completed: </p>
          <input id = "checkBox" type="checkbox" onChange= {(event)=>props.setCompleted(event.target.checked)}></input>
        </div>

        

        <div id = "profits">
          <p>Profits: {(parseFloat(outRevenue) - parseFloat(outCost)).toFixed(2)}</p>
        </div>

        <div id = "profits">
          <p>Revenue: {parseFloat(outRevenue).toFixed(2)}</p>
        </div>
      </div>
    </div>
    
    
  );
};

export default GlobalFilter;
