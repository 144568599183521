import "./Admin.scss";
import {useState} from "react";
import axios from 'axios'


const IngredientOrderTransaction = (props) => {

    const [isAdd, setIsAdd] = useState("sell")
    const [addValue, setAddValue] = useState(0)
    const [costId, setCostId] = useState(0)
    const [buyCost, setBuyCost] = useState(0)
    const [costName, setCostName] = useState("")

    function updateDrink(){
        console.log(costId)
        if(isAdd != "sell")
            axios.post("/api/cost/transactions/buy", {
                "reqCostId": costId,
                "reqCount": addValue,
                "reqBuyCost":buyCost
            }, {headers: { "Content-Type": "application/json" }})
            .then(
            props.setRefresh(!props.refresh)
            )
        else{
            axios.post("/api/cost/transactions/use", {
                "reqCostId": costId,
                "reqCount": -addValue
            }, {headers: { "Content-Type": "application/json" }})
            .then(
            props.setRefresh(!props.refresh)
            )
        }
    }

    return (
        <div className = "transaction">
             <div className = "transaction__add">
                <label htmlFor="trans-type">Buy or Sell</label>
                <select name = "trans-type" onChange = {(e) => setIsAdd(e.target.value)}>
                    <option value = "sell">Use # of Ingredient</option>
                    <option value = "add">Add # of Ingredient</option>
                    <option value = "addCost">Add One Time Cost</option>
                </select >
                <label hidden = {isAdd != "add"} className = "transaction__name--label">Ingredient Name</label>
                <select hidden = {isAdd != "add"} onChange={(e)=>setCostId(e.target.value)} value={props.data.CostName}>
                    <option hidden = {isAdd != "add"} >Please Select an Ingredient</option>
                    {props.data.map((e) => {
                        return <option hidden = {isAdd != "add"} value={e.PK_CostId}>Name: {e.CostName} Size: {e.Size} Notes: {e.Notes}</option>;
                    })}
                </select>
                
                <label hidden = {isAdd != "sell"} className = "transaction__name--label">Ingredient Name</label>
                <select hidden = {isAdd != "sell"} onChange={(e)=>setCostId(e.target.value)} value={props.transactionData.CostName}>
                    <option hidden = {isAdd != "sell"} >Please Select an Ingredient</option>
                    {props.transactionData.map((e) => {
                        return <option hidden = {isAdd != "sell"} value={e.PK_CostId}>Name: {e.CostName} Size: {e.Size} Notes: {e.Notes}</option>;
                    })}
                </select>

                <label hidden = {isAdd != "addCost"} className = "transaction__name--label">Cost Name</label>
                <select hidden = {isAdd != "addCost"} onChange={(e)=>setCostId(e.target.value)} value={props.transactionData.CostName}>
                    <option hidden = {isAdd != "addCost"} >Please Select a Cost</option>
                    {props.costData.map((e) => {
                        return <option hidden = {isAdd != "addCost"} value={e.PK_CostId}>Name: {e.CostName} Size: {e.Size} Notes: {e.Notes}</option>;
                    })}
                </select>

                <label className = "transaction__amount--label">How many?</label>
                <input type = "number" onChange={(e)=>setAddValue(e.target.value)}></input>
                <br></br>
                <p hidden = {isAdd != "add"}>Ingredient Cost</p>
                <input hidden = {isAdd != "add"} onChange={(e)=>setBuyCost(e.target.value)} ></input>

                <p hidden = {isAdd != "addCost"}>Bought For:</p>
                <input hidden = {isAdd != "addCost"} onChange={(e)=>setBuyCost(e.target.value)} ></input>

            </div>
            <div className = "transaction__preview">
                <h4>This action will result in:</h4>
                <p>{isAdd != "sell" ? 'Adding '+parseInt(addValue)+' '+ costName + ' to Stock' +' that cost: $'+buyCost: 'Using '+parseInt(addValue)+' ' + costName + 'from stock'}</p>
                <button onClick={()=>updateDrink()}>Confirm</button>
            </div>
           
        </div>
    );
};

export default IngredientOrderTransaction;