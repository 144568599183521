import "./CheckoutForm.scss"
import { useState, useRef } from "react";


const CheckoutForm = (props) => {

    const saveName = (event) => {
        props.setName(document.getElementById("saveName").value)
    }

    const saveEmail = (event) => {
        props.setEmail(document.getElementById("saveEmail").value)
    }

    const saveInsta = (event) => {
        props.setInsta(document.getElementById("saveInsta").value)
    }

    const saveNotes = (event) => {
        props.setNotes(document.getElementById("saveNotes").value)
    }

    return (
        <div className="checkout__form">
            <h1 className="checkout__title checkout__contact">Contact Info</h1>
            <form className="checkout__field">
                <label className = "checkout__label">Full Name</label>
                <input className = "checkout__input" id = "saveName" onChange={saveName} placeholder = "Name" type = "text"></input>
                <label className = "checkout__label" >Email Address</label>
                <input className = "checkout__input" id = "saveEmail" onChange={saveEmail} placeholder = "Email@Domain.com" type = "email"></input>
                <label className = "checkout__label" >Instagram Handle (Optional)</label>
                <input className = "checkout__input" id = "saveInsta" onChange={saveInsta} placeholder = "@thescobaby"></input>
                <label className = "checkout__label">Order Notes</label>
                <input className = "checkout__input" id = "saveNotes" onChange={saveNotes} placeholder = "Please mention if you can pickup."></input>
                
            </form>
            <div className = "checkout__disclaimer">
                <p className = "checkout__disclaimer--text">An email confirmation will be sent. Drinks that are currently unavailable may take a longer time for your order to be processed. Kombucha contains a small amount of caffeine from the tea and is slightly alcoholic from the fermentation process.</p>
            </div>
        </div>
    );
};

export default CheckoutForm;