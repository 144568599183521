import "./Admin.scss";
import { useMemo } from "react";
import { useGlobalFilter, useTable } from "react-table";
import { Reviews } from "./columns";
import mockOrders from "../../data/mockOrders.json";
import { useState, useRef } from "react";

import axios from 'axios'
import { Toast } from 'primereact/toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function Review(props) {

  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [cancelId, setCancelId] = useState(0);

  const handleClickOpen = (id) => {
    setOpen(true);
    setCancelId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function saveValue(cell, request){
    console.log(cell)
    axios.put("/api/customer/request/updateinfo",{
      "reqContactInfo": request[0].value,
      "reqRequestInfo": request[1].value,
      "reqRequestId": parseInt(cell.PK_RequestId)
  })
    .then(
        props.setRefresh(props.refresh)
    );
  }


  const accept = () => {
    var id = parseInt(cancelId)
    axios.delete("/api/customer/request/delete", {data:{
      "reqRequestId": id
    }, headers: { "Content-Type": "application/json" }})
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deleted!', life: 3000 });
  })
    setOpen(false);
    props.setRefresh(!props.refresh)
    return;
  }
  
  
  const columns = useMemo(() => Reviews, []);
  const data = props.data;


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );


  return (
  <div>
    
      <div className = "genTable">
    <Toast ref={toast} />
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      {"Delete?"}
      </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={accept} autoFocus>
            Yes
          </Button>
        </DialogActions>
    </Dialog>
    <table {...getTableProps()} className="order__table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th> <a>     </a> </th>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
            <th></th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              <td> 
                <div>
                  <Button id = "cancelButton" variant="outlined" onClick={() => handleClickOpen(row.original.PK_RequestId)}>
                    x
                  </Button>
                </div> 
              </td>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}><div contentEditable='true' onInput={e => cell.value = e.currentTarget.textContent}> {cell.render("Cell")} </div></td>
                );
              })}
                <td><button onClick= {()=>saveValue(row.original, row.cells)}>save</button></td>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
    <div id = "reviewFilter">
        Page:{" "}
        <button id = "change" onClick = {()=>props.setPage(props.page - 1)}>  &lt; </button>
        <p>{props.page}</p>
        <button id = "change" onClick = {()=>props.setPage(props.page + 1)}> &gt; </button>
      </div>
  </div>
    
   
  );
}

export default Review;
