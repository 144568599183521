import Error from "../Components/Error/Error"

const ErrorPage = () => {
    return (
        <div>
            <Error/>
        </div>
    );
};

export default ErrorPage;