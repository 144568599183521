import "./Checkout.scss"
import CheckoutItem from "../CheckoutItem/CheckoutItem"
import background from "../../assets/fruit-design1.png"
import CheckoutForm from "../CheckoutForm/CheckoutForm"
import { useState } from "react";


const Checkout = (props) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [insta, setInsta] = useState('');
    const [notes, setNotes] = useState('');

    return (
        <div className="checkout" id = "checkoutId">
            <div className="checkout__content">
                <div className="checkout__review">
                    <div className="checkout__inner-box">
                            <CheckoutForm setEmail = {setEmail} setName = {setName} setInsta = {setInsta} setNotes = {setNotes} />
                    </div>
                </div>
                <div className="checkout__summary">
                    <div className="checkout__inner-box">
                    <CheckoutItem email = {email} name = {name} insta = {insta} notes = {notes} total = {props.total} setTotal = {props.setTotal}/>
                    </div>
                </div>
            </div>
            <img src={background} className="checkout__background"></img>
        </div>
    );
};

export default Checkout;