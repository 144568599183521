import "./FurtherDetails.scss"
import axios from 'axios'
import { useRef } from "react";
import { Toast } from 'primereact/toast';

function FurtherDetails() {
    const toast = useRef(null);

    function getEmail() {
        var inpEmail = document.getElementById("emailInput").value;
        axios.post("/api/customer/request/create", {
            "reqRequestType": "email",
            "reqContactInfo": String(inpEmail)
        }, { headers: { "Content-Type": "application/json" }})
        .then(() => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Success', life: 3000 });
        })
        .catch(() => {
            toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Error with Email Input. This email has already been submitted or the format of the email is incorrect. Please double check', life: 3000 });
        }) 
        
    }
    
    return(
        <>
        <div className = "email-update">
            <p id = "updates" className = "heading-small fw-bold">Sign Up for Updates!</p>
            <div className = "email-container">
                <input type ="email" placeholder ="Your Email"  id = "emailInput"></input>
            </div>
            <div className = "button-container">
                <Toast ref={toast} />
                <button className = "emailButton" onClick={getEmail} >Submit</button>
            </div>
        </div>
        <div className = "instagram-plug-container">
            <p id = "connect" className = "heading-small fw-bold">Connect with me</p>
            <div className = "icon-container">
                <a className = "instagram-follow-btn" href = "https://www.instagram.com/thescobaby/" target = "_blank">
                    <img className = "instagram-follow-btn__image" src = "/imagesPNG/instagram-logo.png" />
                </a>
                <a className = "instagram-follow-btn" href = "mailto:demo@demo.com" target = "_blank">
                    <img className = "instagram-follow-btn__image" src = "/imagesPNG/email_logo.png" />
                </a>
            </div>  
        </div>
        </>
    )
}
export default FurtherDetails