import "./Error.scss";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react"

function Error() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 2000)
    }, [])
    return (
        <>
            <div className="error-container">
                <p className="heading-med">Work in Progress!</p>
            </div>
        </>
    )
}

export default Error