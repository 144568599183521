import ProductCarousel from "../ProductCarousel/ProductCarousel"
import "./Products.scss"
import axios from 'axios'


import { useState, useEffect } from "react";

function Products(prop) {

    const [message, setMessage] = useState([]);
    const [currentDrink, setCurrentDrink] = useState({})

    useEffect(() => {
        axios.get("/api/drink/findall")
        .then((response) => {
            setMessage(response.data)
            setCurrentDrink(response.data[0])

        });
    }, []);    

    function changeDrink(DrinkOrder) {
        const clickedProduct = message.find((product) => product.DrinkOrder === DrinkOrder);
        setCurrentDrink(clickedProduct);
    }

    return (
        <>
            <div className="products" id = "productsId">
                <h1 className="products__title">My Products</h1>
                <p className="products__subtitle">Choose from these great flavours</p>
                <div className="products__carousel">
                    <ProductCarousel
                    total = {prop.total} 
                    setTotal = {prop.setTotal}
                    list={message} 
                    current={currentDrink}
                    changeDrink = {changeDrink} />
                </div>
            </div>
        </>
    )
}

export default Products